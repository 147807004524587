@media screen and (min-width: 992px) and (max-width:1200px) {
    .nav>li>a {
        margin-left:9px!important
    }

    .box-open-sanfona-empresa {
        height: 380px
    }

    .nome-prod {
        margin-top: 29px;
        width: 82%
    }

    .nome-prod.size-18 {
        font-size: 15px!important
    }

    .container-blog .row .box-posts-home {
        height: 110px
    }

    .list-menu-mapa-site li a {
        font-size: 12px
    }

    .count-prod {
        top: 149px
    }
    
    .image-produto img{
	    width: 100%;
    }

    .box-cursos-home, .box-cursos-home-black {
        width: 222px;
    }
} 

@media screen and (min-width: 992px) and (max-width:1024px){
	.txt-curso-home-escola a{
		font-size: 13px;
	}
	
	.navbar-nav > li > a, .top-nav-collapse .navbar-nav > li > a{
		font-size: 13px;
	}
}

@media screen and (min-width: 768px) and (max-width:991px) {

    .top-nav-collapse .nav>li>a {
        margin-left: 5px!important
    }

    .box-sanfona-empresa {
        font-size: 12px
    }

    .box-open-sanfona-empresa {
        height: 400px
    }

    .page-int p {
        font-size: 14px
    }

    .nome-prod.size-18 {
        font-size: 14px!important
    }

    .nome-prod {
        margin-top: 32px;
        width: 76%
    }

    .box-prooduto-categoria {
        height: 180px;
        margin-bottom: 30px
    }

    .count-prod {
        padding: 7px 11px;
        top: 120px
    }

    .container-blog .row .box-posts-home {
        height: 127px
    }

    .list-menu-mapa-site li a {
        font-size: 12px
    }

    .bt-labguage {
        display: none
    }
}


@media screen and (max-width: 991px) {
	
	.hero-interna{
		margin-top: 62px;
	}
	
	.navbar-fixed-top .navbar-nav>li.current-menu-item>a,.top-nav-collapse .current-menu-item>a,.top-nav-collapse .navbar-default .navbar-nav>li.current-menu-item>a,.top-nav-collapse .navbar-nav>li.current-menu-item>a {
        color: #000!important
    }
	
    .cont-mapa-site,div#footer {
        text-align:center
    }

    .entry-content {
        margin-top: 30px
    }

    .section {
        padding-bottom: 25px;
        padding-top: 25px
    }

    .hero-home {
        padding-top: 60px
    }

    .txt-bem-vindo h2 {
        font-size: 20px!important
    }

    .txt-bem-vindo h3 {
        font-size: 16px!important
    }

    .navbar-fixed-top {
        background-color: #fff!important
    }

    #logo2 {
        margin-left: 15px;
        padding: 5px 0!important
    }

    .box-slider-header-home {
        width: 360px;
        margin: 0 auto 30px
    }

    .txt-busca {
        margin-bottom: 24px
    }

    .input-busca::-webkit-input-placeholder {
        color: transparent
    }

    .input-busca:-moz-placeholder {
        color: transparent
    }

    .input-busca::-moz-placeholder {
        color: transparent
    }

    .input-busca:-ms-input-placeholder {
        color: transparent
    }

    .fa-search {
        padding-left: 0
    }

    div#footer {
        padding-top: 30px!important
    }

    .box-area-cliente {
        margin-top: 20px
    }

    .container-blog .row {
        margin-bottom: 0!important
    }

    .container-blog .row div {
        margin-bottom: 30px
    }

    .input-news {
        margin-bottom: 10px
    }

    .box-prooduto-categoria {
        height: 210px;
        margin: 0 auto 20px;
        width: 262px!important;
        float: none!important
    }

    .form-download,.nome-prod {
        width: 80%
    }

    .box-prooduto-categoria:nth-last-child(-n+5) {
        margin-bottom: 20px!important
    }

    .faixa-iso {
        padding-top: 25px
    }

    .curso-1,.section-escola-genesis .row>div:first-child {
        margin-bottom: 30px
    }

    .container-blog .row .box-posts-home {
        height: auto
    }

    .box-downloads {
        height: 220px;
        margin: 0 auto 30px;
        width: 270px
    }

    .bt-labguage {
        display: none
    }

    .count-prod {
        top: 162px
    }
    
    .box-produto-home {
	    text-align: center;
	    margin-bottom: 30px;
	}
	
	.box-produto-home img {
	    text-align: center;
	    margin: 0 auto;
	}
	
	.subtit-home {
	    margin-bottom: 40px;
	}
	
	.section-mapa a{
		display: block;
		margin-right: 0px;
	}
	
	.wp-caption{
		width: 100% !important;
	}

    .box-info-escola {
        margin-top: 0px;
    }

    .box-cursos-home, .box-cursos-home-black{
        margin: 0 auto;
        width: 95%;
    }
}

@media screen and (max-width: 400px) {
    .section-cursos-escola .media {
        width:100%!important
    }

    .txt-curso-home-escola a {
        font-size: 11px!important
    }

    .widget_item_depoimento_escola {
        width: auto
    }

    .descricao_itens_widget {
        font-size: 12px
    }

    .box-slider-header-home {
        width: 100%
    }

    .nextpostslink {
        display: inline-block;
        margin-top: 15px !important;
        position: relative;
    }

    .last {
        display: inline-block;
        margin-top: 12px !important;
    }

    .widget_posts_destaques_blog{
        margin-bottom: 20px;
    }

    .widgettitle{
        padding-top: 15px;
    }

    .widget_posts_destaques_blog ul li{
        width: 80px;
        display: inline-block;
        margin-right: 20px;
    }
}

@-moz-document url-prefix() {
    fieldset {
        display: table-cell
    }
}
